<template>
  <div class="">
    <v-layout row wrap class="d-flex">
      <v-flex xs12 md12 lg12 class="pa-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/"
            >Home</router-link
          >
          / {{ $route.params.id }}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg8 class="pa-5">
        <h2 class="mb-5 primary--text">Booking Details</h2>
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Flight</th>
              <th class="fs-12">{{ booking.ac_unique_code }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Date</th>
              <th class="fs-12">{{ booking.date }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Time</th>
              <th class="fs-12">{{ booking.time }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Seats</th>
              <th class="fs-12">{{ booking.seats }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Amount</th>
              <th class="fs-12">{{ booking.amount }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Duration</th>
              <th class="fs-12">{{ booking.duration }} Min.</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Name</th>
              <th class="fs-12">{{ booking.name }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Email</th>
              <th class="fs-12">{{ booking.email }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone</th>
              <th class="fs-12">{{ booking.mobile_number }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">WhatsApp</th>
              <th class="fs-12">{{ booking.whatsapp }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Status</th>
              <th class="fs-12">{{ booking.status }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Mode</th>
              <th class="fs-12">{{ booking.payment_mode }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Channel</th>
              <th class="fs-12">{{ booking.channel }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Created At</th>
              <th class="fs-12">
                {{
                  booking.created_at | moment("dddd, MMMM Do YYYY, h:mm:ss a")
                }}
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Actions</th>
              <th class="fs-12 pt-2 pb-2">
                <v-btn
                  class="primary black--text mr-2"
                  x-large
                  @click="editBooking"
                  >Edit Booking</v-btn
                >
                <v-btn
                  class="red darken-4 white--text"
                  x-large
                  @click="openCancellationDialog"
                  >Cancel Booking</v-btn
                >
              </th>
            </tr>
            <!-- <tr>
                <th class="fs-12 primary--text">Payment Details</th>
                <th class="fs-12"></th>
              </tr> -->
            <!-- <tr>
                <th class="fs-12 primary--text">Options</th>
                <th class="fs-12">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" class="black--text" light v-bind="attrs" v-on="on">
                        Manage Account <span><v-icon>mdi-menu-down</v-icon></span>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>Edit Account</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Reset Password</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Account</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </th>
              </tr> -->
          </tbody>
        </v-simple-table>
        <h2 class="mt-5 primary--text">Pre-flight data</h2>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">Name</th>
                <th class="text-left uppercase primary--text">Age</th>
                <th class="text-left uppercase primary--text">Weight(Kg)</th>
                <th class="text-left uppercase primary--text">Height(Cm)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in booking.pre_flight_data"
                :key="item.createdAt"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.age }}</td>
                <td>{{ item.weight }}</td>
                <td>{{ item.height }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-dialog v-model="dialog" width="800" persistent>
        <v-card>
          <v-card-title class="text-h5 primary--text grey darken-3">
            Confirm Cancellation
          </v-card-title>
          <v-card-text>
            <h2 class="pt-5">Are your sure ?</h2>
            <p class="mt-2">
              Please enter 6 digit OTP received on your registered email to
              cancel the booking.
            </p>
            <div class="d-flex" style="max-width: 50%">
              <v-text-field
                single-line
                outlined
                v-model="otp"
                label="OTP"
                style="width: 200px"
              ></v-text-field>
              <v-btn
                class="primary black--text ml-2"
                x-large
                style="height: 55px"
                @click="cancelBooking"
                >Cancel</v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> CLOSE </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-flex xs12 md4 class="pa-5">
          <h2 class="mb-5 primary--text">Payment Details</h2>
          <v-simple-table v-for="(payment,index) in booking.payment_info" :key="index">
            <tbody v-if="payment.paymentResponse.status == 'SETTLED'">
              <tr>
                <th class="fs-12 primary--text">Merchant Order No</th>
                <th class="fs-12">{{ payment.paymentResponse.merchantOrderNo }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">PayBy Order No</th>
                <th class="fs-12">{{ payment.paymentResponse.orderNo }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Status</th>
                <th class="fs-12">{{ payment.paymentResponse.status }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Paid Amount</th>
                <th class="fs-12">{{ payment.paymentResponse.paymentInfo.paidAmount.amount }} {{ payment.paymentResponse.paymentInfo.paidAmount.currency }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Fee</th>
                <th class="fs-12">{{ payment.paymentResponse.paymentInfo.payeeFeeAmount.amount }} {{ payment.paymentResponse.paymentInfo.payeeFeeAmount.currency }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Settlement Amount</th>
                <th class="fs-12">{{ payment.paymentResponse.paymentInfo.settlementAmount.amount }} {{ payment.paymentResponse.paymentInfo.settlementAmount.currency }}</th>
              </tr>
              <tr>
                <th class="fs-12 primary--text">Card</th>
                <th class="fs-12 pa-5">
                  <p class="pa-0 ma-0">{{ payment.paymentResponse.paymentInfo.cardInfo.first6.slice(0,4) }} **** **** {{ payment.paymentResponse.paymentInfo.cardInfo.last4 }}</p>
                  <p class="pa-0 ma-0">{{ payment.paymentResponse.paymentInfo.cardInfo.brand }}</p>
                  <p class="pa-0 ma-0">Type - {{ payment.paymentResponse.paymentInfo.cardInfo.cardType }}</p>
                  <p class="pa-0 ma-0">Issue Country - {{ payment.paymentResponse.paymentInfo.cardInfo.issueCountry }}</p>
                </th>
              </tr>
            </tbody>
          </v-simple-table>
        </v-flex> -->
    </v-layout>
  </div>
</template>
  
    <script>
import { BASE_URL } from "../../config";
import Axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      booking: {},
      dialog: false,
      account : {}
    };
  },
  computed: {
    ...mapGetters(["USER"]),
  },
  mounted() {
    this.fetchBookingDetails();
    this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      let url = BASE_URL + "/agent/" + this.USER.admin.profile;
      let { data } = await Axios.get(url);
      this.account = data;
    },
    editBooking() {
      this.$router.push({
        name: "EditAgentBookingDetail",
        params: { id: this.$route.params.id },
      });
    },
    async openCancellationDialog() {
      this.dialog = true;
      let url = BASE_URL + "/agent/send-otp";
      let payload = {
        email: this.account.email,
        context: "cancellation",
      };
      let { data } = await Axios.post(url, payload);
    },
    async cancelBooking() {
      let url = BASE_URL + "/agent/verify-otp";
      let payload = {
        email: this.account.email,
        otp: this.otp,
      };
      let { data } = await Axios.post(url, payload);
      if (data.success) {
        let payload = {
          booking_id: this.$route.params.id,
        };
        let url = BASE_URL + "/booking/cancel/agent";
        let { data } = await Axios.post(url, payload);
        this.fetchBookingDetails()
        this.dialog = false
        this.$toastr.s(
          "Booking has been cancelled sucessfully",
          "Booking Cancelled"
        );
      }else {
        this.$toastr.e(
          "Please enter valid OTP to continue",
          "Wrong OTP"
        );
      }
    },
    async fetchBookingDetails() {
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url = BASE_URL + "/booking/" + this.$route.params.id;
      let { data } = await Axios.get(url, { headers });
      this.booking = data[0];
    },
  },
};
</script>
  
  <style scoped>
.fs-12 {
  font-size: 12pt !important;
}
</style>
  
  